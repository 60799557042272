import resourceBookingApi from 'api/helix/resourceBooking';
import { LocationBasedHelixApiCall } from 'api/helix/utils/types';
import { HelixApiResourcesTypesResponse, ResourceType } from 'store/resources/types';

interface GetResourceTypesParams extends LocationBasedHelixApiCall {
  appInstanceConfigUuid: string;
}

export const resourcesApi = resourceBookingApi.injectEndpoints({
  endpoints: build => ({
    getResourcesTypes: build.query<ResourceType[], GetResourceTypesParams>({
      query: ({ locationId, appInstanceConfigUuid }) => ({
        url: `resources/types`,
        params: { include: 'configured', locationId, appInstanceConfigUuid },
      }),
      transformResponse: (result: HelixApiResourcesTypesResponse) => result.types.filter(type => !!type.length),
    }),
  }),
});
