import React, { ChangeEvent } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { RadioForm, RadioOption } from '@hqo/react-components-library/dist/molecules/radioForm';

import {
  LocationsListContainer,
  CheckboxFormContainer,
  AllLocationToggle,
  AllLocationTitle,
  StyledSwitchV2,
} from './styles';
import { useToggleNativeHeader } from 'hooks/use-toggle-native-header/use-toggle-native-header.hook';
import { useToggleSwipeGestures } from 'hooks/use-toggle-swipe-gestures/use-toggle-swipe-gestures.hook';
import { useIntl } from 'react-intl';
import { LocationOption } from 'components/location-option/location-option';
import { useTheme } from 'styled-components';
import { HqoTheme } from '@hqo/react-components-library/dist/hqo-theme-provider';

export interface CheckboxOption {
  label: string;
  subtitle: string;
  value: string;
  selected: boolean;
  imageUrl?: string;
  locationCity: string;
}

interface SelectLocationModalContentProps {
  onRadioButtonClick: (event: ChangeEvent<HTMLInputElement>) => void;
  onLocationsChange: (event: ChangeEvent<HTMLInputElement>) => void;
  selectedLocationName: string;
  options: CheckboxOption[] | RadioOption[];
  selectAllChecked: boolean;
  onSelectAllChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const SelectLocationModalContent = ({
  onRadioButtonClick,
  onLocationsChange,
  selectedLocationName,
  options,
  selectAllChecked,
  onSelectAllChange,
}: SelectLocationModalContentProps): JSX.Element => {
  useToggleNativeHeader();
  useToggleSwipeGestures();
  const intl = useIntl();
  const { colors } = useTheme() as HqoTheme;

  const { resourceBookingPortfolioBased, resourceBookingShowMapView } = useFlags();

  return (
    <LocationsListContainer>
      {resourceBookingPortfolioBased && resourceBookingShowMapView ? (
        <CheckboxFormContainer data-testid="checkbox-form-test-id">
          <AllLocationToggle>
            <StyledSwitchV2
              onChange={onSelectAllChange}
              value="all"
              checked={selectAllChecked}
              name="all"
              hideIcons
              color={colors.palette.systemGreen}
            />
            <AllLocationTitle>{intl.formatMessage({ id: 'select_all_locations' })}</AllLocationTitle>
          </AllLocationToggle>
          {options.map((checkbox: CheckboxOption) => (
            <div key={checkbox.value} data-testid={`${checkbox.value}-test-id`} className="button-container">
              <LocationOption
                label={checkbox.label}
                onChange={onLocationsChange}
                value={checkbox.value}
                checked={checkbox.selected}
                checkboxName={checkbox.value}
                imageUrl={checkbox.imageUrl}
                subtitle={checkbox.locationCity}
              />
            </div>
          ))}
        </CheckboxFormContainer>
      ) : (
        <RadioForm
          className="locations-list"
          options={options as RadioOption[]}
          onPress={onRadioButtonClick}
          selected={selectedLocationName}
          isExpandedTouchArea
          disabled={false}
        />
      )}
    </LocationsListContainer>
  );
};
