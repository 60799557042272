import { ContentContainer } from './styles';
import { Grid } from 'pages/resources/styles';
import React from 'react';
import { Content } from 'pages/resources/content';
import { ResourcesCounterBlock } from 'pages/resources/components/resources-counter-block';
import { useSelector } from 'react-redux';
import { selectResources } from 'store/resources/selectors';
import { useAppInstanceConfigFeature } from 'hooks/use-app-instance-config-feature.hook';
import { ResourcesListSkeleton } from './resources-list-skeleton';
import { BuildingInfo } from './building-info';

interface ResourcesListProps {
  isLoadingState: boolean;
}

export const ResourcesSwipeModalContent = ({ isLoadingState }: ResourcesListProps): JSX.Element => {
  const resources = useSelector(selectResources);
  const { isCompactUiEnabled } = useAppInstanceConfigFeature();
  const isNoResources = resources?.length === 0 && !isLoadingState;

  return isLoadingState ? (
    <ResourcesListSkeleton />
  ) : (
    <ContentContainer data-testid="resources-list-container">
      <BuildingInfo />
      <ResourcesCounterBlock />
      <Grid isNoResources={isNoResources} isCompactUiEnabled={isCompactUiEnabled} isSwipeModalContent>
        <Content resources={resources} />
      </Grid>
    </ContentContainer>
  );
};
