import { ACTION_STATUSES, FontLocation, LANDING_PATH } from 'shared/consts';
import { ThemeLocationFont, useThemeFont } from 'hooks/use-theme-font.hook';
import { getResources, resetInfiniteResourcesState } from 'store/resources/actions';
import { resourcesState } from 'store/resources/selectors';
import { selectAppInstanceConfigsStatus, selectCurrentLocationId } from 'store/app-instance-configs/selectors';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FilterParams } from 'store/resources/types';
import { Resource } from 'store/resource/types';
import { getFilterParams } from 'utils';
import { useLocation } from 'react-router-dom';
import { useMiniappSdk } from 'components/miniapp-sdk-provider/miniapp-sdk.hook';
import { useOwnerParams } from 'hooks/use-owner-params.hook';
import { useSearchParams } from 'hooks/use-search-params.hook';
import { useTimeZone } from 'hooks/use-timezone.hook';
import { HEADER_BUTTON_MODE } from '@hqo/hqo-miniapp-client-sdk';
import { useFilteringEnabled } from 'hooks/use-filtering-enabled.hook';
import { useTermsAndConditionsVisibility } from 'hooks/use-terms-and-conditions-visibility.hook';
import { useResetResourceState } from 'pages/resources/hooks/useResetResourceState.hook';
import { useResourceLoadingHandler } from 'pages/resources/hooks/resourceLoadingHandler.hook';
import { useAppInstanceConfigFeature } from 'hooks/use-app-instance-config-feature.hook';
import { useIsSmallViewportWidth } from '@hqo/react-components-library/dist/viewport/';
import { getTransactions } from 'store/transactions/actions';
import { selectTransactions, selectTransactionsStatus } from 'store/transactions/selectors';

interface UseResourcesPageReturnValues {
  resources: Array<Resource>;
  isLoadingState: boolean;
  themeFontTitle: ThemeLocationFont;
  isCompactUiEnabled: boolean;
  shouldShowMapView: boolean;
  shouldShowResourcesSwipeModal: boolean;
}

const HEADER_HEIGHT = 109;
const FOOTER_HEIGHT = 20;
const ELEMENT_HEIGHT = 84;

export const useResourcesPage = (): UseResourcesPageReturnValues => {
  const isMobileDevice = useIsSmallViewportWidth();
  useTermsAndConditionsVisibility();
  const dispatch = useDispatch();
  const timeZone = useTimeZone();
  const { pathname } = useLocation();
  const { ownerType, ownerUuid } = useOwnerParams();
  const { ...queryParams } = useSearchParams();

  const { filterByType, startDate, startTime, endTime, duration, capacity, presetWindows, filterByFloor, startDates } =
    queryParams;

  const { resources } = useSelector(resourcesState);
  const themeFontTitle = useThemeFont(FontLocation.TITLE);
  const locationId = useSelector(selectCurrentLocationId);
  const appInstanceConfigsStatus = useSelector(selectAppInstanceConfigsStatus);
  const getTransactionsStatus = useSelector(selectTransactionsStatus);
  const isFilteringEnabled = useFilteringEnabled();
  const { isCompactUiEnabled, isMapViewEnabled } = useAppInstanceConfigFeature();
  const isLoadingState = useResourceLoadingHandler(isFilteringEnabled);
  const client = useMiniappSdk();
  const transactions = useSelector(selectTransactions);

  const shouldDispatchGetResources = useMemo(
    () =>
      (isMapViewEnabled && pathname === LANDING_PATH) ||
      pathname === '/resource-booking/resources' ||
      pathname === '/resource-booking/resources/filter-by',
    [pathname, isMapViewEnabled],
  );

  const shouldShowResourcesSwipeModal = useMemo(
    () => isMapViewEnabled && isMobileDevice,
    [isMapViewEnabled, isMobileDevice],
  );

  const shouldShowMapView = useMemo(
    () => (isMobileDevice ? isMapViewEnabled : !isLoadingState && isMapViewEnabled),
    [isLoadingState, isMapViewEnabled, isMobileDevice],
  );

  const offsetElements = Math.round((window.innerHeight - HEADER_HEIGHT - FOOTER_HEIGHT) / ELEMENT_HEIGHT);

  useEffect(() => {
    client?.header.showHeader();
    client?.header.setHeader('', HEADER_BUTTON_MODE.HISTORY);
    client?.swipe.enableBackSwipe();
  }, [client]);

  useResetResourceState();

  useEffect(() => {
    const searchParams = {
      types: filterByType,
      startDate,
      startTime,
      endTime,
      duration,
      capacity,
      presetWindows,
      startDates,
      floor: filterByFloor,
    } as FilterParams;

    const filterParams = getFilterParams({ ...searchParams });

    if (
      shouldDispatchGetResources &&
      ownerType &&
      ownerUuid &&
      appInstanceConfigsStatus === ACTION_STATUSES.FULFILLED &&
      locationId
    ) {
      if (isCompactUiEnabled) {
        dispatch(resetInfiniteResourcesState());
        dispatch(
          getResources.request({ ownerType, ownerUuid, filterParams: { ...filterParams, limit: offsetElements } }),
        );
      } else {
        dispatch(getResources.request({ ownerType, ownerUuid, filterParams: { ...filterParams } }));
      }
    }
  }, [
    shouldDispatchGetResources,
    ownerType,
    filterByType,
    startDate,
    startTime,
    endTime,
    duration,
    capacity,
    timeZone,
    dispatch,
    appInstanceConfigsStatus,
    locationId,
    isCompactUiEnabled,
    pathname,
    ownerUuid,
    presetWindows,
    filterByFloor,
    startDates,
    offsetElements,
  ]);

  useEffect(() => {
    if (
      appInstanceConfigsStatus === ACTION_STATUSES.FULFILLED &&
      getTransactionsStatus !== ACTION_STATUSES.PENDING &&
      getTransactionsStatus !== ACTION_STATUSES.FULFILLED &&
      locationId &&
      !transactions?.length &&
      isMapViewEnabled
    ) {
      dispatch(getTransactions.request({}));
    }
  }, [appInstanceConfigsStatus, dispatch, locationId, transactions, isMapViewEnabled, getTransactionsStatus]);

  return {
    resources,
    isLoadingState,
    themeFontTitle,
    isCompactUiEnabled,
    shouldShowMapView,
    shouldShowResourcesSwipeModal,
  };
};
