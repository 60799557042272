import { AppInstanceConfigsState } from './types';
import { RootState } from 'store/reducer';
import { createSelector } from 'reselect';
import { configSelector } from 'store/config/selectors';
import { selectResources } from 'store/resources/selectors';
import { selectBuildingUuid } from '../building/selectors';
import { ResourceBookingEnum } from './enums';
import { DEFAULT_LATITUDE, DEFAULT_LONGITUDE } from 'pages/resources/components/map-view/constants';

export const appInstanceConfigsSelector = (state: RootState): AppInstanceConfigsState => state.appInstanceConfigs;

export const selectAppInstanceConfigs = createSelector(
  [appInstanceConfigsSelector, configSelector],
  (appInstanceConfigState, config) => {
    const appInstanceConfigs = appInstanceConfigState.app_instance_configs ?? [];
    if (config.singleAppInstanceConfig && appInstanceConfigs.length) {
      const appInstanceConfigUUID = appInstanceConfigState.currentAppInstanceConfigUuid || appInstanceConfigs[0].uuid;

      return appInstanceConfigs.filter(appInstanceConfig => appInstanceConfig.uuid === appInstanceConfigUUID);
    }
    if (config.singleLocationConfig && appInstanceConfigs.length) {
      const locationId = appInstanceConfigState.currentLocationId;
      if (locationId) {
        return appInstanceConfigs.filter(appInstanceConfig => appInstanceConfig.location_id === locationId);
      }
      return [appInstanceConfigs[0]];
    }

    return appInstanceConfigs;
  },
);

export const selectAppInstanceConfigsStatus = createSelector(
  [appInstanceConfigsSelector],
  configs => configs.getAppInstanceConfigs.status,
);

export const selectAppInstanceConfigsError = createSelector(
  [appInstanceConfigsSelector],
  configs => configs.getAppInstanceConfigs.error,
);

export const selectCurrentAppInstanceConfigUuid = createSelector(
  [appInstanceConfigsSelector],
  configs => configs.currentAppInstanceConfigUuid,
);

export const selectCurrentAppInstanceConfig = createSelector([appInstanceConfigsSelector], appInstanceConfigState =>
  appInstanceConfigState.app_instance_configs?.find(
    config => config.uuid === appInstanceConfigState.currentAppInstanceConfigUuid,
  ),
);

export const selectCurrentLocationId = createSelector([appInstanceConfigsSelector], configs => {
  return configs.currentLocationId;
});

export const selectCurrentLocationCoordinates = createSelector(
  [selectCurrentLocationId, selectAppInstanceConfigs],
  (locationId, appInstanceConfigs) => {
    const location = appInstanceConfigs.find(config => config.location_id === locationId);

    return location?.location_coordinates;
  },
);

export const selectCurrentLocationName = createSelector(
  [selectCurrentLocationId, selectAppInstanceConfigs],
  (locationId, appInstanceConfigs) => {
    const location = appInstanceConfigs.find(config => config.location_id === locationId);

    return location?.location_name;
  },
);

export const selectAllLocations = createSelector([appInstanceConfigsSelector], configs =>
  configs.app_instance_configs.reduce(
    (map: Record<string, { latitude: number; longitude: number; locationId: string }>, config) => {
      return {
        ...map,
        [config.location_id]: {
          latitude: config.location_coordinates?.latitude,
          longitude: config.location_coordinates?.longitude,
          locationId: config.location_id,
        },
      };
    },
    {},
  ),
);

export const selectCurrentBuildingLocationCoordinates = createSelector(
  [selectBuildingUuid, selectAppInstanceConfigs],
  (buildingUuid, appInstanceConfigs) => {
    const nativeLocation = appInstanceConfigs.find(
      config =>
        config?.vertical_adapter?.name === ResourceBookingEnum.NativeAdapter && config?.building?.uuid === buildingUuid,
    );

    return {
      latitude: nativeLocation?.location_coordinates?.latitude ?? DEFAULT_LATITUDE,
      longitude: nativeLocation?.location_coordinates?.longitude ?? DEFAULT_LONGITUDE,
    };
  },
);

export const selectSelectedLocations = createSelector(
  [selectAllLocations, selectResources],
  (allLocations, resources) => {
    const selectedLocations = resources?.flatMap(resource => {
      return resource.buildings
        ?.map(building => allLocations[building.uuid])
        .filter(location => location && location.locationId);
    });
    const uniqKey = 'locationId';

    return [...new Map(selectedLocations?.map(location => [location?.[uniqKey], location])).values()];
  },
);

export const selectIsCurrentLocationIdUpdating = createSelector(
  [appInstanceConfigsSelector],
  configs => configs.isCurrentLocationIdUpdating,
);

export const selectOnboardUserStatus = createSelector(
  [appInstanceConfigsSelector],
  configs => configs.onboardUser?.status,
);

export const selectOnboardUserError = createSelector(
  [appInstanceConfigsSelector],
  configs => configs.onboardUser.error,
);
