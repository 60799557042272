import { OwnerType, OWNER_TYPES, TRANSACTION_STATES } from 'shared/consts';

import { PaymentAsyncActionTypes } from 'shared/consts/payment-error-types';
import { RoomLayout } from '../resource/types';
import { AddOnDto } from '../add-ons/types';
import { AttendeeType } from '../transactions/types';

export interface CartState extends CreateCartResponse {
  cart: Order;
  transaction: TransactionObject;
  getCart: AsyncActionTypes;
  createCart: AsyncActionTypes;
  submitCart: PaymentAsyncActionTypes;
  complete3DSCart: AsyncActionTypes;
}

export interface CreateCartAPIResponse {
  data: CreateCartResponse;
}
export interface CreateCartResponse {
  cart_id: string;
}

export interface CreateCartDto {
  type: OrderType;
  owner_type: OwnerType;
  owner_id: string;
  building_uuid: string;
  items?: Array<ItemDto>;
  lang?: LocaleEnum;
}

export enum OrderType {
  ORDER_AHEAD = 'ORDER_AHEAD',
  SERVICE_BOOKING_APPOINTMENT = 'SERVICE_BOOKING_APPOINTMENT',
  SERVICE_BOOKING_MEMBERSHIP = 'SERVICE_BOOKING_MEMBERSHIP',
  SERVICE_BOOKING_CLASS = 'SERVICE_BOOKING_CLASS',
  EVENTS = 'EVENTS',
  RESOURCE_BOOKING = 'RESOURCE_BOOKING',
}

export enum LocaleEnum {
  en_US = 'en_US',
  en_GB = 'en_GB',
  fr_FR = 'fr_FR',
  fr_CA = 'fr_CA',
  nl_NL = 'nl_NL',
  nb_NO = 'nb_NO',
  en_CA = 'en_CA',
  de_DE = 'de_DE',
  en_IE = 'en_IE',
  es_ES = 'es_ES',
  pl_PL = 'pl_PL',
  it_IT = 'it_IT',
}

export interface ItemDto {
  id: string;
  order_item_id?: string;
  type: ItemType;
  quantity: number;
  notes?: string;
  price?: number;
  tax?: number;
  discount?: number;
  display_info: DisplayInfo;
  display_order?: number;
  resource_booking?: CreateBookingDto;
}

interface CreateBookingDto {
  resource_id?: number | string;
  name: string;
  start_at: Date;
  end_at: Date;
  description?: string;
  timezone?: string;
  canceled_at?: Date;
  contacted?: boolean;
  transaction_id?: string;
  attendees?: number;
  form_responses?: FormResponse[];
  room_layout?: RoomLayout;
  add_ons?: Array<AddOnDto>;
}

export enum ItemType {
  SERVICE_BOOKING_MEMBERSHIP = 'SERVICE_BOOKING_MEMBERSHIP',
  SERVICE_BOOKING_APPOINTMENT = 'SERVICE_BOOKING_APPOINTMENT',
  SERVICE_BOOKING_CLASS = 'SERVICE_BOOKING_CLASS',
  ORDER_AHEAD_ITEM = 'ORDER_AHEAD_ITEM',
  EVENT = 'EVENT',
  RESOURCE_BOOKING = 'RESOURCE_BOOKING',
}

export interface DisplayInfo {
  title: string;
  lang?: string;
  subtitle?: string;
  description1?: string;
  description2?: string;
  image1?: string;
  image2?: string;
  cancellation_description?: string;
}

export interface GetCartApiResponse {
  data: GetCartApiResponsePayload;
}

export interface SubmitCartApiResponse {
  data: SubmitCartApiResponsePayload;
}

export interface GetCartApiResponsePayload {
  cart: Order;
}

export interface SubmitCartApiResponsePayload {
  order: Order;
}

export interface Complete3DSCartApiResponse {
  data: {
    transaction: TransactionObject;
  };
}
export interface Order {
  building_id: string;
  config: AppInstanceConfig;
  id: string;
  items: Array<Item>;
  external_id: string;
  owner_id: string;
  owner_type: OWNER_TYPES;
  type: OrderType;
  users: Array<string>;
  total_summary: OrderTotal;
  ui_enablements: UIEnablements;
  vendor: Vendor;
  transaction: TransactionObject;
  attempt_3dsecure?: boolean;
  three_ds_version?: string;
  transaction_id: string;
  transaction_uuid?: string;
  unit_code?: string;
}

/**
 * API: List of all items in TransactionObject in https://docs.spreedly.com/reference/api/v1/#complete-3ds-2
 */
export interface TransactionObject {
  token: string;
  succeeded: boolean;
  message: string;
  response: {
    success: boolean;
    message: string;
    error_code: string | null;
    error_detail: string | null;
  };
  required_action?: string;
  state: TRANSACTION_STATES | string;
  retain_on_success: boolean;
  payment_method_added: boolean;
}

export interface FormattedAttendees {
  email: string;
  type: AttendeeType;
}

export interface SubmitOrderDto {
  cart_id: string;
  payment_method_id?: string;
  payment_token_id?: string;
  attempt_3dsecure?: boolean;
  three_ds_version?: string;
  callback_url?: string;
  redirect_url?: string;
  browser_info?: string;
  locationId?: string;
  unit_code?: string;
  attendees?: Array<FormattedAttendees>;
}

export interface CompleteCartDto {
  transaction_token: string;
}

export interface Item {
  id: string;
  order_item_id?: string;
  type: ItemType;
  quantity: number;
  notes?: string;
  price?: number;
  tax?: number;
  discount?: number;
  discount_applied?: number;
  discount_applied_type?: DiscountTypes;
  display_order?: number;
  display_info: DisplayInfo;
  resource_booking?: {
    start_at: string;
    end_at: string;
    name: string;
    form_responses?: Array<FormResponse>;
    unit_code?: string;
    add_ons?: Array<AddOnDto>;
    room_layout?: RoomLayout;
  };
  status?: string;
}

export interface OrderTotal {
  taxes_total?: number;
  currency_type?: string;
  total?: number;
  subtotal?: number;
  prediscount_subtotal?: number;
  discount_applied?: number;
  discount_applied_type?: DiscountTypes;
  tax_summary: TaxSummary;
  discount_summary?: Array<DiscountSummary>;
}

export interface DiscountSummary {
  promo_code: string;
  name: string;
  type: DiscountTypes;
  amount: number;
  calculated_discount: number;
}

export enum DiscountTypes {
  PERCENT = 'percent',
  FIXED = 'fixed',
}

export interface TaxSummary {
  taxes_total: number;
  taxes?: Array<Tax>;
}

export interface Tax {
  id: string;
  description: string;
  tax_amount: number;
}

interface AppInstanceConfig {
  config?: AdapterConfig;
  id: number;
  uuid: string;
  external_id?: string;
  app_config_id?: string;
  base_url?: string;
  owner_type: OWNER_TYPES;
  owner_id: number;
  vertical_id: number;
  vertical: Vertical;
  payment_gateway_id?: number;
  payment_gateway: PaymentGatewayResponse;
  vertical_adapter_id: number;
  enabled: boolean;
  created_at: Date;
  updated_at: Date;
  deleted_at?: Date;
}

export enum VERTICAL_API_CODES {
  SERVICE_BOOKING = 'service-booking',
  RESOURCE_BOOKING = 'resource-booking',
  ORDER_AHEAD = 'order-ahead',
}

export enum VERTICAL_NAMES {
  RESOURCE_BOOKING = 'RESOURCE_BOOKING',
}

export interface Vertical {
  id: number;
  uuid: string;
  name: VERTICAL_NAMES;
  label: string;
  description?: string;
  api_code: VERTICAL_API_CODES;
  vertical_id: number;
  created_at: string;
  updated_at: string;
  deleted_at?: string | null;
}

export interface AdapterConfig {
  adapter_config_id?: string;
  adapter?: string;
  appointment?: boolean;
  group?: boolean;
  enable_membership_signup?: boolean;
  enable_registration?: boolean;
  enable_qr_code?: boolean;
  enable_promotions?: boolean;
  _type?: string;
}

interface PaymentGatewayResponse {
  supports_apple_pay?: boolean;
  supports_google_pay?: boolean;
  inapp_deals_enabled?: boolean;
  description?: string | null;
  gateway_token_key?: string;
  enabled?: boolean;
  preauthorize?: boolean;
  supports_3dsecure: boolean;
  version_3dsecure: string;
}

export interface UIEnablements {
  apple_pay_enabled?: boolean;
  google_pay_enabled?: boolean;
  deals_enabled?: boolean;
  gratuity_enabled?: boolean;
  spreedly_env_token: string;
}

export interface Vendor {
  id?: number;
  uuid?: string;
  slug?: string;
  name?: string;
  tagline?: string;
  description?: string;
  url?: string;
  logo_url?: string;
  hero_image_url?: string;
  address_1?: string;
  address_2?: string;
  city?: string;
  state?: string;
  zipcode?: string;
  street_number?: string;
  route?: string;
  locality?: string;
  administrative_area_1?: string;
  administrative_area_2?: string;
  country?: string;
  formatted_address?: string;
  postal_code?: string;
  timezone?: string;
  locale?: string;
  latitude?: string;
  longitude?: string;
  place_id?: string;
  twitter_handle?: string;
  facebook_handle?: string;
  phone_number?: string;
  created_at: Date;
  updated_at: Date;
  deleted_at?: Date;
  directions?: string;
  type?: string;
  menu_link?: string;
  starting_time?: string;
  ending_time?: string;
  user_count?: number;
  is_service_provider?: boolean;
  short_description?: string;
  long_description?: string;
  is_ordering_enabled?: boolean;
  delivery_enabled?: boolean;
  pickup_enabled?: boolean;
  delivery_radius?: number;
  waiver_url?: string;
  linkout_url?: string;
  linkout_label?: string;
  linkout_icon?: string;
}

export interface ExternalPaymentLabel {
  label: string;
  value: string;
}
