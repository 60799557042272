import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import { RadioOption } from '@hqo/react-components-library/dist/molecules/radioForm';
import { track } from '@hqo/web-tracking';

import { TRACK_EVENT_NAMES, TRACK_EVENT_TYPES } from 'shared/consts';
import { replace } from 'store/router/actions';
import {
  selectAppInstanceConfigs,
  selectCurrentAppInstanceConfigUuid,
  selectCurrentLocationId,
} from 'store/app-instance-configs/selectors';
import {
  isCurrentLocationIdUpdating,
  updateCurrentAppInstanceConfigUuid,
  updateCurrentLocationId,
} from 'store/app-instance-configs/actions';
import { useCurrentAdapterName } from 'hooks/use-current-adapter-name';
import { resetGetResourcesTypes } from 'store/resources/actions';
import { CheckboxOption } from 'components/select-location-modal/select-location.modal-content';

interface UseSelectLocationModalReturnValues {
  currentLocationName: string;
  onLocationPickerClick: VoidFunction;
  onRadioButtonClick: (event: ChangeEvent<HTMLInputElement>) => void;
  selectedValue: string;
  onModalClose: VoidFunction;
  options: RadioOption[] | CheckboxOption[];
  onButtonClick: VoidFunction;
  onLocationsChange: (event: ChangeEvent<HTMLInputElement>) => void;
  selectAllChecked: boolean;
  onSelectAllChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const useSelectLocationModal = (): UseSelectLocationModalReturnValues => {
  const dispatch = useDispatch();
  const appInstanceConfigs = useSelector(selectAppInstanceConfigs);
  const currentAppInstanceConfigUuid = useSelector(selectCurrentAppInstanceConfigUuid);
  const currentLocationId = useSelector(selectCurrentLocationId);
  const { search, pathname } = useLocation();
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const mapLocationIds = (locationIds: string, mapCallback: (locationId: string) => string) => {
    return locationIds
      ?.split(',')
      .map(locationId => {
        return mapCallback(locationId);
      })
      .filter(locationId => {
        return locationId;
      })
      .join(',');
  };

  const locationsFromPath = useMemo(() => {
    const query = new URLSearchParams(search);

    return query.get('locationId')?.split(',');
  }, [search]);

  const constructCurrentSelectedValue = (locationIds: string) => {
    return mapLocationIds(locationIds, locationId => {
      return `${currentAppInstanceConfigUuid}_${locationId}`;
    });
  };

  const goToPreviousPage = useCallback(() => {
    const removePath = '/select-location-modal';
    const backRoutePathName = pathname.substring(0, pathname.indexOf(removePath));
    const backRoute = `${backRoutePathName.length ? backRoutePathName : '/'}${search}`;
    dispatch(replace(backRoute));
  }, [dispatch, pathname, search]);

  const currentSelectedValue = constructCurrentSelectedValue(currentLocationId);
  const [selectedValue, setSelectedValue] = useState('');

  useEffect(() => {
    setSelectedValue(constructCurrentSelectedValue(currentLocationId));
  }, [currentLocationId, locationsFromPath, search]);

  const currentLocationName = useMemo<string>(() => {
    if (currentLocationId) {
      return mapLocationIds(currentLocationId, locationId => {
        const appInstanceConfig = appInstanceConfigs?.find(
          config => config.uuid === currentAppInstanceConfigUuid && config.location_id === locationId,
        );

        return appInstanceConfig?.location_name;
      });
    }
    const currentAppInstanceConfig = appInstanceConfigs?.find(
      config => config.uuid === currentAppInstanceConfigUuid && config.location_default,
    );

    return currentAppInstanceConfig?.location_name;
  }, [appInstanceConfigs, currentAppInstanceConfigUuid, currentLocationId]);

  const [selectedLocationName, setSelectedLocationName] = useState(currentLocationName);

  const appInstanceConfigUuid = useMemo<string>(() => {
    const selectedValues = selectedValue?.split(',').filter(selectedVal => {
      return selectedVal;
    });

    return !selectedValues
      ? appInstanceConfigs?.[0]?.uuid
      : appInstanceConfigs?.find(config => `${config.uuid}_${config.location_id}` === selectedValues?.[0])?.uuid;
  }, [appInstanceConfigs, selectedValue]);

  const selectedLocationId = useMemo(() => {
    return mapLocationIds(selectedValue, value => {
      return appInstanceConfigs?.find(config => config.location_id && `${config.uuid}_${config.location_id}` === value)
        ?.location_id;
    });
  }, [appInstanceConfigs, selectedValue]);

  const onLocationPickerClick = useCallback(() => {
    if (pathname === '/') {
      dispatch(replace(`select-location-modal${search}`));
    } else {
      dispatch(replace(`${pathname}/select-location-modal${search}`));
    }
  }, [dispatch, search, pathname]);

  const onModalClose = useCallback(() => {
    setSelectedValue(currentSelectedValue);
    setSelectedLocationName(currentLocationName);
    goToPreviousPage();
    // if not all options are in currentSelectedValue, set selectAllChecked to false
    if (!currentSelectedValue.includes(options.map((option: CheckboxOption) => option.value).join(','))) {
      setSelectAllChecked(false);
    }
  }, [dispatch, setSelectedValue, currentSelectedValue, goToPreviousPage]);

  const onRadioButtonClick = useCallback(
    (event: any) => {
      setSelectedValue(event.target.value);
    },
    [setSelectedValue],
  );

  const onLocationsChange = useCallback(
    (event: any) => {
      let currentSelectedLocationIds = selectedValue?.split(',');
      if (event.target.checked && !currentSelectedLocationIds.includes(event.target.value)) {
        currentSelectedLocationIds.push(event.target.value);
      } else {
        currentSelectedLocationIds = currentSelectedLocationIds.filter(currentLocId => {
          return currentLocId !== event.target.value;
        });
      }
      const selectedLocationIds = currentSelectedLocationIds
        .filter(currentLocId => {
          return currentLocId;
        })
        .join(',');

      setSelectedValue(selectedLocationIds);
      if (!event.target.checked) {
        setSelectAllChecked(false);
      }
    },
    [setSelectedValue, selectedValue],
  );

  const options = useMemo<RadioOption[] | CheckboxOption[]>(() => {
    const locationOptions = appInstanceConfigs.map(appInstanceConfig => {
      return {
        label: appInstanceConfig.location_name,
        subtitle: appInstanceConfig.location_address,
        value: `${appInstanceConfig.uuid}_${appInstanceConfig.location_id}`,
        selected: selectedValue?.split(',').includes(`${appInstanceConfig.uuid}_${appInstanceConfig.location_id}`),
        imageUrl: appInstanceConfig?.location_logo_url,
        locationCity: appInstanceConfig?.location_city,
      };
    });

    return locationOptions.sort((a: { label: string }, b: { label: string }) => {
      const locationNameA = a.label.toLowerCase();
      const locationNameB = b.label.toLowerCase();
      if (locationNameA < locationNameB) return -1;
      if (locationNameA > locationNameB) return 1;

      return 0;
    }) as unknown as RadioOption[] | CheckboxOption[];
  }, [appInstanceConfigs, selectedValue]);

  const onSelectAllChange = useCallback(
    (event: any) => {
      setSelectAllChecked(event.target.checked);
      if (selectAllChecked) {
        setSelectAllChecked(false);
      } else setSelectAllChecked(true);
      // If the "select all" checkbox is checked, select all options
      // Otherwise, unselect all options
      const newSelectedValue = event.target.checked
        ? options.map((option: CheckboxOption) => option.value).join(',')
        : '';
      setSelectedValue(newSelectedValue);
    },
    [selectAllChecked, options],
  );

  useEffect(() => {
    // if all options are in selectedValue, set selectAllChecked to true
    if (selectedValue?.split(',').length === options.length) {
      setSelectAllChecked(true);
    } else {
      setSelectAllChecked(false);
    }
  }, [selectedValue, locationsFromPath, search, options, currentLocationId]);

  const currentAdapterName = useCurrentAdapterName();

  const onButtonClick = useCallback(() => {
    track(
      TRACK_EVENT_NAMES.LOCATION_CHANGE_DONE_CLICK,
      {
        type: TRACK_EVENT_TYPES.ACTION,
        service_provider_name: currentAdapterName,
        location_name: selectedLocationName,
      },
      { sendToHqoTracking: true },
    );
    goToPreviousPage();
    dispatch(resetGetResourcesTypes());
    dispatch(updateCurrentLocationId({ currentLocationId: selectedLocationId }));
    dispatch(isCurrentLocationIdUpdating({ isCurrentLocationIdUpdating: true }));
    dispatch(updateCurrentAppInstanceConfigUuid({ currentAppInstanceConfigUuid: appInstanceConfigUuid }));
  }, [currentAdapterName, selectedValue, dispatch, selectedLocationId, appInstanceConfigUuid, goToPreviousPage]);

  return {
    currentLocationName,
    onLocationPickerClick,
    onRadioButtonClick,
    selectedValue,
    onModalClose,
    options,
    onButtonClick,
    onLocationsChange,
    selectAllChecked,
    onSelectAllChange,
  };
};
