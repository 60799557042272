import { Grid } from 'pages/resources/styles';
import { Content } from 'pages/resources/content';
import React, { useMemo } from 'react';
import { Skeleton } from 'components/skeleton';
import { useSelector } from 'react-redux';
import { selectGetResourcesLoadingStatus, selectResources } from 'store/resources/selectors';
import { useLocation } from 'react-router-dom';
import { LANDING_PATH } from 'shared/consts';
import { selectTransactions } from 'store/transactions/selectors';
import { BookingStatus } from 'store/bookings/types';
import { TransactionDetails } from 'store/transactions/types';

interface ResourcesListProps {
  isCompactUiEnabled: boolean;
}

export const ResourcesList = ({ isCompactUiEnabled }: ResourcesListProps): JSX.Element => {
  const resources = useSelector(selectResources);
  const isResourcesLoading = useSelector(selectGetResourcesLoadingStatus);
  const { pathname } = useLocation();
  const transactions = useSelector(selectTransactions);

  const formatTransactions: TransactionDetails[] = useMemo(() => {
    const filteredTransactions = transactions?.filter(
      transaction =>
        transaction?.details?.status !== BookingStatus.CANCELED &&
        transaction?.details?.status !== BookingStatus.DENIED_AVAILABLE &&
        transaction?.details?.status !== BookingStatus.DENIED_UNAVAILABLE,
    );

    return filteredTransactions?.reduce((acc, transaction) => {
      acc.push(transaction);

      return acc.sort((a, b) => new Date(a.details.start_at).getTime() - new Date(b.details.start_at).getTime());
    }, []);
  }, [transactions]);

  const isNoResources = resources?.length === 0 && !isResourcesLoading;
  const isUpcomingBookingsSectionVisible = pathname === LANDING_PATH && Boolean(formatTransactions?.length);

  return isResourcesLoading ? (
    <Skeleton isCompactUiEnabled={isCompactUiEnabled} />
  ) : (
    <Grid
      isNoResources={isNoResources}
      isCompactUiEnabled={isCompactUiEnabled}
      isUpcomingBookingsSectionVisible={isUpcomingBookingsSectionVisible}
    >
      <Content resources={resources} />
    </Grid>
  );
};
