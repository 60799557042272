import * as actions from './actions';

import { ResourcesAPIResponse } from 'store/resources/types';
import { catchError, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';

import { AjaxResponse } from 'rxjs/ajax';
import { Epic } from 'redux-observable';
import { RootAction } from 'store/actions';
import { RootDependencies } from 'store/dependencies';
import { RootState } from 'store/reducer';
import { isActionOf } from 'typesafe-actions';
import { of } from 'rxjs';
import { transformErrorIntoSerializableError } from 'store/utils';

export const getResourcesEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) =>
  action$.pipe(
    filter(isActionOf(actions.getResources.request)),
    withLatestFrom(state$),
    switchMap(([{ payload }, state]) => {
      const { ownerType, ownerUuid, filterParams } = payload;

      return apiClient(state)
        .getResources(ownerType, ownerUuid, filterParams)
        .pipe(
          map(({ response }: AjaxResponse<ResourcesAPIResponse>) => {
            return actions.getResources.success({
              resources: response.data.resources,
              total: response.total,
            });
          }),
          catchError((error: Error) =>
            of(actions.getResources.failure({ error: transformErrorIntoSerializableError(error) })),
          ),
        );
    }),
  );

export const getInfiniteResourcesEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) =>
  action$.pipe(
    filter(isActionOf(actions.getInfiniteResources.request)),
    withLatestFrom(state$),
    switchMap(([{ payload }, state]) => {
      const { ownerType, ownerUuid, filterParams } = payload;

      return apiClient(state)
        .getResources(ownerType, ownerUuid, filterParams)
        .pipe(
          map(({ response }: AjaxResponse<ResourcesAPIResponse>) => {
            return actions.getInfiniteResources.success({
              resources: response.data.resources,
              indexOfSet: payload.indexOfSet,
              total: response.total,
            });
          }),
          catchError((error: Error) =>
            of(actions.getInfiniteResources.failure({ error: transformErrorIntoSerializableError(error) })),
          ),
        );
    }),
  );
