import { useOwnerParams } from 'hooks/use-owner-params.hook';
import { useSearchParams } from 'hooks/use-search-params.hook';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ACTION_STATUSES } from 'shared/consts';
import { getInfiniteResources } from 'store/resources/actions';
import {
  selectGetInfiniteResourcesStatus,
  selectInfiniteResources,
  selectInfiniteResourcesTotal,
} from 'store/resources/selectors';
import { FilterParams } from 'store/resources/types';
import { getFilterParams } from 'utils';
import { RESOURCE_TILE_HEIGHT, EXTRA_CONTENT_HEIGHT } from './infinite-content.enums';

export const useInfiniteContent = (): { limitOfElements: number } => {
  const limitOfElements = Math.round(
    (document.documentElement.clientHeight - EXTRA_CONTENT_HEIGHT) / RESOURCE_TILE_HEIGHT,
  );
  const dispatch = useDispatch();
  const infiniteResources = useSelector(selectInfiniteResources);
  const infiniteResourcesTotal = useSelector(selectInfiniteResourcesTotal);
  const getInfiniteResourcesStatus = useSelector(selectGetInfiniteResourcesStatus);
  const { ...queryParams } = useSearchParams();
  const { filterByType, startDate, startTime, endTime, duration, capacity, filterByFloor } = queryParams;
  const { ownerType, ownerUuid } = useOwnerParams();
  const isInfiniteResourcesLoading = getInfiniteResourcesStatus.status === ACTION_STATUSES.PENDING;
  const numberDownloadedResources = Object.values(infiniteResources).flat().length + limitOfElements;

  const onScroll = useCallback((): void => {
    const { pageYOffset } = window || {};
    const { clientHeight } = document.documentElement || {};
    const { scrollHeight } = document.documentElement || {};
    if (
      clientHeight + pageYOffset > scrollHeight - 10 &&
      !isInfiniteResourcesLoading &&
      infiniteResourcesTotal !== numberDownloadedResources
    ) {
      const searchParams = {
        types: filterByType,
        startDate,
        startTime,
        endTime,
        duration,
        capacity: +capacity,
        floor: filterByFloor,
      } as FilterParams;
      const filterParams = getFilterParams({ ...searchParams });

      const offset =
        numberDownloadedResources < infiniteResourcesTotal
          ? limitOfElements * (Object.keys(infiniteResources)?.length + 1)
          : infiniteResourcesTotal;

      dispatch(
        getInfiniteResources.request({
          ownerType,
          ownerUuid,
          filterParams: {
            ...filterParams,
            limit: limitOfElements,
            offset,
          },
          indexOfSet: Object.keys(infiniteResources)?.length + 1,
        }),
      );
    }
  }, [
    isInfiniteResourcesLoading,
    filterByType,
    startDate,
    startTime,
    endTime,
    duration,
    dispatch,
    ownerType,
    ownerUuid,
    limitOfElements,
    infiniteResources,
  ]);

  useEffect(() => {
    if (limitOfElements < infiniteResourcesTotal) {
      window.addEventListener('scroll', onScroll);
    }

    return () => window.removeEventListener('scroll', onScroll);
  }, [onScroll, limitOfElements, infiniteResourcesTotal]);

  return { limitOfElements };
};
