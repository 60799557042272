import { CHECKOUT_BREAK_POINT } from 'shared/consts';
import styled from 'styled-components';
import { skeletonAnimation } from 'components/skeleton/styles';

export const Container = styled.div`
  padding: 28px 16px;
  overflow: hidden;
  max-width: 100vw;
  .desktop {
    display: none;
  }
  @media (min-width: ${CHECKOUT_BREAK_POINT}px) {
    .desktop {
      display: flex;
    }
    .mobile {
      display: none;
    }
  }
`;

export const Square = styled.div`
  width: 30px;
  height: 28px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme?.colors?.greys?.sharedGreyBackground ?? '#EDEDED'};
  ${skeletonAnimation};
`;

export const Bar = styled.div`
  width: 100%;
  flex-grow: 1;
  height: 28px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme?.colors?.greys?.sharedGreyBackground ?? '#EDEDED'};
  ${skeletonAnimation};
`;

export const DesktopBar = styled.div`
  width: 286px;
  height: 28px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme?.colors?.greys?.sharedGreyBackground ?? '#EDEDED'};
  ${skeletonAnimation};
`;

export const CounterBar = styled.div`
  margin-top: 25px;
  width: 98px;
  height: 15px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme?.colors?.greys?.sharedGreyBackground ?? '#EDEDED'};
  ${skeletonAnimation};

  @media (min-width: ${CHECKOUT_BREAK_POINT}px) {
    margin-top: 0;
  }
`;

export const TopCounterBar = styled.div`
  width: 98px;
  height: 15px;
  margin-bottom: 10px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme?.colors?.greys?.sharedGreyBackground ?? '#EDEDED'};
  ${skeletonAnimation};

  @media (min-width: ${CHECKOUT_BREAK_POINT}px) {
    margin-bottom: 0;
  }
`;

export const FlexContainer = styled.div<{
  isColumnDirection?: boolean;
  gap: number;
  isWidth?: boolean;
  isWrap?: boolean;
  flex?: number;
}>`
  display: flex;
  ${({ isWidth }) => isWidth && 'width: 100%'};
  flex-direction: ${({ isColumnDirection }) => (isColumnDirection ? 'column' : 'row')};
  gap: ${({ gap }) => gap}px;
  ${({ isWrap }) => isWrap && 'flex-wrap: wrap'};
  ${({ flex }) => flex && `flex: ${flex}`};
`;

export const Resource = styled.div<{ isDesktop?: boolean }>`
  width: 100%;
  height: 78px;
  border-radius: 4px;
  ${({ isDesktop }) => isDesktop && 'width: 220px; height: 120px;'}
  background-color: ${({ theme }) => theme?.colors?.greys?.sharedGreyBackground ?? '#EDEDED'};
  ${skeletonAnimation};
`;
